// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId3) => {
  ChainId3[ChainId3["ETHEREUM"] = 1] = "ETHEREUM";
  ChainId3[ChainId3["GOERLI"] = 5] = "GOERLI";
  ChainId3[ChainId3["BSC"] = 56] = "BSC";
  ChainId3[ChainId3["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId3[ChainId3["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
  ChainId3[ChainId3["ZKSYNC"] = 324] = "ZKSYNC";
  ChainId3[ChainId3["OPBNB_TESTNET"] = 5611] = "OPBNB_TESTNET";
  ChainId3[ChainId3["OPBNB"] = 204] = "OPBNB";
  ChainId3[ChainId3["POLYGON_ZKEVM"] = 1101] = "POLYGON_ZKEVM";
  ChainId3[ChainId3["POLYGON_ZKEVM_TESTNET"] = 1442] = "POLYGON_ZKEVM_TESTNET";
  ChainId3[ChainId3["ARBITRUM_ONE"] = 42161] = "ARBITRUM_ONE";
  ChainId3[ChainId3["ARBITRUM_GOERLI"] = 421613] = "ARBITRUM_GOERLI";
  ChainId3[ChainId3["ARBITRUM_SEPOLIA"] = 421614] = "ARBITRUM_SEPOLIA";
  ChainId3[ChainId3["SCROLL_SEPOLIA"] = 534351] = "SCROLL_SEPOLIA";
  ChainId3[ChainId3["LINEA"] = 59144] = "LINEA";
  ChainId3[ChainId3["LINEA_TESTNET"] = 59140] = "LINEA_TESTNET";
  ChainId3[ChainId3["ZEUSCHAIN"] = 34504] = "ZEUSCHAIN";
  ChainId3[ChainId3["PULSECHAIN"] = 369] = "PULSECHAIN";
  ChainId3[ChainId3["UNICHAIN_SEPOLIA"] = 1301] = "UNICHAIN_SEPOLIA";
  ChainId3[ChainId3["WORLDCHAIN"] = 480] = "WORLDCHAIN";
  ChainId3[ChainId3["APECHAIN"] = 33139] = "APECHAIN";
  ChainId3[ChainId3["MORPHCHAIN"] = 2818] = "MORPHCHAIN";
  ChainId3[ChainId3["INKCHAIN"] = 763373] = "INKCHAIN";
  ChainId3[ChainId3["BASECHAIN"] = 8453] = "BASECHAIN";
  ChainId3[ChainId3["BASE_TESTNET"] = 84531] = "BASE_TESTNET";
  ChainId3[ChainId3["BASE_SEPOLIA"] = 84532] = "BASE_SEPOLIA";
  ChainId3[ChainId3["SEPOLIA"] = 11155111] = "SEPOLIA";
  return ChainId3;
})(ChainId || {});
var testnetChainIds = [
  5 /* GOERLI */,
  97 /* BSC_TESTNET */,
  280 /* ZKSYNC_TESTNET */,
  5611 /* OPBNB_TESTNET */,
  1442 /* POLYGON_ZKEVM_TESTNET */,
  421613 /* ARBITRUM_GOERLI */,
  534351 /* SCROLL_SEPOLIA */,
  59140 /* LINEA_TESTNET */,
  84531 /* BASE_TESTNET */,
  11155111 /* SEPOLIA */,
  421614 /* ARBITRUM_SEPOLIA */,
  84532 /* BASE_SEPOLIA */
];
var defaultChainId = 8453 /* BASECHAIN */;
var defaultChainName = "BASECHAIN";

// src/zeusChain.ts
var zeusChain = {
  id: 34504,
  network: "zeuschain",
  name: "ZeusChain",
  nativeCurrency: {
    name: "Zeus",
    symbol: "ZEUS",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet-rpc.zeuschainscan.io"]
    },
    public: {
      http: ["https://mainnet-rpc.zeuschainscan.io"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "Zeusscan",
      url: "https://zeuschainscan.io"
    },
    default: {
      name: "Zeusscan",
      url: "https://zeuschainscan.io"
    },
    etherscan: {
      name: "Zeusscan",
      url: "https://zeuschainscan.io"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 19258213
    },
    multicall3: {
      address: "0x614f8698661bB8c933E022E8474dd220A5994161",
      blockCreated: 1974040
    }
  }
};

// src/pulseChain.ts
var pulseChain = {
  id: 369,
  network: "pulsechain",
  name: "PulseChain",
  nativeCurrency: {
    name: "Pulse",
    symbol: "PLS",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.pulsechain.com"],
      webSocket: ["wss://ws.pulsechain.com"]
    },
    public: {
      http: ["https://pulsechain-rpc.publicnode.com", "https://rpc-pulsechain.g4mm4.io", "https://rpc.pulsechain.com"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "PulseScan",
      url: "https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#",
      apiUrl: "https://api.scan.pulsechain.com/api"
    },
    default: {
      name: "PulseScan",
      url: "https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#"
    },
    etherscan: {
      name: "PulseScan",
      url: "https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 14353601
    }
  }
};

// src/unichainSepolia.ts
var unichainSepolia = {
  id: 1301,
  network: "unichainsepolia",
  name: "Unichain Sepolia",
  nativeCurrency: {
    name: "Eth",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://sepolia.unichain.org"]
    },
    public: {
      http: ["https://unichain-sepolia.g.alchemy.com/v2/2xDwDH9tllLY9Yki2XZzJG5nWcMHw3BS", "https://sepolia.unichain.org"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "UnichainScan",
      url: "https://unichain-sepolia.blockscout.com",
      apiUrl: "https://unichain-sepolia.blockscout.com/api"
    },
    default: {
      name: "UnichainScan",
      url: "https://sepolia.uniscan.xyz"
    },
    etherscan: {
      name: "UnichainScan",
      url: "https://sepolia.uniscan.xyz"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 1734315
    }
  }
};

// src/worldChain.ts
var worldChain = {
  id: 480,
  network: "worldchain",
  name: "WorldChain",
  nativeCurrency: {
    name: "Eth",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://worldchain-mainnet.g.alchemy.com/public"]
    },
    public: {
      http: ["https://worldchain-mainnet.g.alchemy.com/public"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "WorldScan",
      url: "https://worldchain-mainnet.explorer.alchemy.com/",
      apiUrl: "https://worldchain-mainnet.explorer.alchemy.com/api"
    },
    default: {
      name: "WorldScan",
      url: "https://worldchain-mainnet.explorer.alchemy.com"
    },
    etherscan: {
      name: "WorldScan",
      url: "https://worldchain-mainnet.explorer.alchemy.com"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 1944174
    }
  }
};

// src/apeChain.ts
var apeChain = {
  id: 33139,
  network: "apechain",
  name: "ApeChain",
  nativeCurrency: {
    name: "Ape",
    symbol: "APE",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://apechain.calderachain.xyz/http"]
    },
    public: {
      http: ["https://apechain.calderachain.xyz/http"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "ApeScan",
      url: "https://apescan.io",
      apiURL: "https://api.apescan.io/api"
    },
    default: {
      name: "ApeScan",
      url: "https://apescan.io"
    },
    etherscan: {
      name: "ApeScan",
      url: "https://apescan.io"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 20889
    }
  }
};

// src/morphChain.ts
var morphChain = {
  id: 2818,
  network: "morphchain",
  name: "MorphChain",
  nativeCurrency: {
    name: "ETHER",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.morphl2.io"]
    },
    public: {
      http: ["https://rpc.morphl2.io"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "MorphScan",
      url: "https://explorer.morphl2.io",
      apiURL: "https://explorer-api.morphl2.io/api"
    },
    default: {
      name: "MorphScan",
      url: "https://explorer.morphl2.io"
    },
    etherscan: {
      name: "MorphScan",
      url: "https://explorer.morphl2.io"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0x35f965903A85e7528437C3Ce0b4bdfbc4E5Fc27c",
      blockCreated: 52602
    }
  }
};

// src/inkChain.ts
var inkChain = {
  id: 763373,
  network: "inkchain",
  name: "Ink Sepolia",
  nativeCurrency: {
    name: "ETHER",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://rpc-gel-sepolia.inkonchain.com"]
    },
    public: {
      http: ["https://rpc-gel-sepolia.inkonchain.com"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "InkScan",
      url: "https://explorer-sepolia.inkonchain.com",
      apiURL: "https://explorer-sepolia.inkonchain.com/api"
    },
    default: {
      name: "InkScan",
      url: "https://explorer-sepolia.inkonchain.com"
    },
    etherscan: {
      name: "InkScan",
      url: "https://explorer-sepolia.inkonchain.com"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 52602
    }
  }
};

// src/baseChain.ts
var baseChain = {
  id: 8453,
  network: "basechain",
  name: "BaseChain",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.base.org"]
    },
    public: {
      http: ["https://base-rpc.publicnode.com", "https://base.llamarpc.com", "https://base.blockpi.network/v1/rpc/public"]
    }
  },
  blockExplorers: {
    blockscout: {
      name: "BaseScan",
      url: "https://base.blockscout.com",
      apiURL: "https://base.blockscout.com/api"
    },
    default: {
      name: "BaseScan",
      url: "https://basescan.org"
    },
    etherscan: {
      name: "BaseScan",
      url: "https://basescan.org"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x"
    },
    ensUniversalResolver: {
      address: "0x",
      blockCreated: 0
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 5022
    }
  }
};

// src/chainNames.ts
var chainNames = {
  [1 /* ETHEREUM */]: "eth",
  [5 /* GOERLI */]: "goerli",
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bscTestnet",
  [42161 /* ARBITRUM_ONE */]: "arb",
  [421613 /* ARBITRUM_GOERLI */]: "arbGoerli",
  [1101 /* POLYGON_ZKEVM */]: "polygonZkEVM",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: "polygonZkEVMTestnet",
  [324 /* ZKSYNC */]: "zkSync",
  [280 /* ZKSYNC_TESTNET */]: "zkSyncTestnet",
  [59144 /* LINEA */]: "linea",
  [59140 /* LINEA_TESTNET */]: "lineaTestnet",
  [204 /* OPBNB */]: "opBNB",
  [5611 /* OPBNB_TESTNET */]: "opBnbTestnet",
  [34504 /* ZEUSCHAIN */]: "zeuschain",
  [369 /* PULSECHAIN */]: "pulsechain",
  [1301 /* UNICHAIN_SEPOLIA */]: "unichainsepolia",
  [480 /* WORLDCHAIN */]: "worldchain",
  [33139 /* APECHAIN */]: "apechain",
  [2818 /* MORPHCHAIN */]: "morphchain",
  [763373 /* INKCHAIN */]: "inkchain",
  [8453 /* BASECHAIN */]: "basechain",
  [84531 /* BASE_TESTNET */]: "baseTestnet",
  [534351 /* SCROLL_SEPOLIA */]: "scrollSepolia",
  [11155111 /* SEPOLIA */]: "sepolia",
  [421614 /* ARBITRUM_SEPOLIA */]: "arbSepolia",
  [84532 /* BASE_SEPOLIA */]: "baseSepolia"
};
var chainNameToChainId = Object.entries(chainNames).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName]: chainId,
    ...acc
  };
}, {});
var defiLlamaChainNames = {
  [56 /* BSC */]: "bsc",
  [1 /* ETHEREUM */]: "ethereum",
  [5 /* GOERLI */]: "",
  [97 /* BSC_TESTNET */]: "",
  [42161 /* ARBITRUM_ONE */]: "arbitrum",
  [421613 /* ARBITRUM_GOERLI */]: "",
  [1101 /* POLYGON_ZKEVM */]: "polygon_zkevm",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: "",
  [324 /* ZKSYNC */]: "era",
  [280 /* ZKSYNC_TESTNET */]: "",
  [59140 /* LINEA_TESTNET */]: "",
  [84531 /* BASE_TESTNET */]: "",
  [204 /* OPBNB */]: "op_bnb",
  [5611 /* OPBNB_TESTNET */]: "",
  [534351 /* SCROLL_SEPOLIA */]: "",
  [59144 /* LINEA */]: "linea",
  [34504 /* ZEUSCHAIN */]: "zeus",
  [369 /* PULSECHAIN */]: "pulse",
  [1301 /* UNICHAIN_SEPOLIA */]: "",
  [480 /* WORLDCHAIN */]: "",
  [33139 /* APECHAIN */]: "",
  [2818 /* MORPHCHAIN */]: "",
  [763373 /* INKCHAIN */]: "",
  [8453 /* BASECHAIN */]: "base",
  [11155111 /* SEPOLIA */]: "",
  [421614 /* ARBITRUM_SEPOLIA */]: "",
  [84532 /* BASE_SEPOLIA */]: ""
};

// src/utils.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getLlamaChainName(chainId) {
  return defiLlamaChainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}
function isTestnetChainId(chainId) {
  return testnetChainIds.includes(chainId);
}

// src/subgraphs.ts
var publicSubgraphParams = {
  // Public key for nodereal subgraph endpoint
  noderealApiKey: "19bd2b3f75c24e23bb8a0e9d4f55b271"
};
var V3_SUBGRAPHS = getV3Subgraphs(publicSubgraphParams);
var V2_SUBGRAPHS = getV2Subgraphs(publicSubgraphParams);
var BLOCKS_SUBGRAPHS = getBlocksSubgraphs(publicSubgraphParams);
var STABLESWAP_SUBGRAPHS = {
  [56 /* BSC */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap",
  [42161 /* ARBITRUM_ONE */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap-arb"
};
function getV3Subgraphs({ noderealApiKey, theGraphApiKey }) {
  return {
    [1 /* ETHEREUM */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-eth",
    [5 /* GOERLI */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-goerli",
    [56 /* BSC */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc",
    [97 /* BSC_TESTNET */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-chapel",
    [42161 /* ARBITRUM_ONE */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-arb",
    [421613 /* ARBITRUM_GOERLI */]: "https://api.thegraph.com/subgraphs/name/chef-jojo/exhange-v3-arb-goerli",
    [1101 /* POLYGON_ZKEVM */]: `https://gateway-arbitrum.network.thegraph.com/api/${theGraphApiKey}/subgraphs/id/7HroSeAFxfJtYqpbgcfAnNSgkzzcZXZi6c75qLPheKzQ`,
    [1442 /* POLYGON_ZKEVM_TESTNET */]: "",
    [324 /* ZKSYNC */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-zksync/version/latest",
    [280 /* ZKSYNC_TESTNET */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-zksync-testnet/version/latest",
    [59144 /* LINEA */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-linea/version/latest",
    [59140 /* LINEA_TESTNET */]: "https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exchange-v3-linea-goerli",
    [34504 /* ZEUSCHAIN */]: "https://graph.zeusx.io/subgraphs/name/v3-node",
    [369 /* PULSECHAIN */]: "https://graph.astraswap.io/subgraphs/name/v3-pulse",
    [1301 /* UNICHAIN_SEPOLIA */]: "https://api.studio.thegraph.com/query/91444/unichain-v3/version/latest",
    [480 /* WORLDCHAIN */]: "https://graph-worldchain.astraswap.io/subgraphs/name/v3-node",
    [33139 /* APECHAIN */]: "https://graph-apechain.astraswap.io/subgraphs/name/v3-node",
    [2818 /* MORPHCHAIN */]: "https://graph-morphchain.astraswap.io/subgraphs/name/v3-node",
    [763373 /* INKCHAIN */]: "https://graph-inkchain.astraswap.io/subgraphs/name/v3-node",
    [8453 /* BASECHAIN */]: "https://api.studio.thegraph.com/query/91444/base-v3/version/latest",
    [84531 /* BASE_TESTNET */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-base-testnet/version/latest",
    [204 /* OPBNB */]: `https://open-platform-ap.nodereal.io/${noderealApiKey}/opbnb-mainnet-graph-query/subgraphs/name/pancakeswap/exchange-v3`,
    [5611 /* OPBNB_TESTNET */]: "",
    [534351 /* SCROLL_SEPOLIA */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-scroll-sepolia/version/latest",
    [11155111 /* SEPOLIA */]: "",
    [421614 /* ARBITRUM_SEPOLIA */]: "",
    [84532 /* BASE_SEPOLIA */]: ""
  };
}
function getV2Subgraphs({ noderealApiKey, theGraphApiKey }) {
  return {
    [56 /* BSC */]: "https://proxy-worker-api.pancakeswap.com/bsc-exchange",
    [1 /* ETHEREUM */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth",
    [1101 /* POLYGON_ZKEVM */]: `https://gateway-arbitrum.network.thegraph.com/api/${theGraphApiKey}/subgraphs/id/37WmH5kBu6QQytRpMwLJMGPRbXvHgpuZsWqswW4Finc2`,
    [280 /* ZKSYNC_TESTNET */]: "https://api.studio.thegraph.com/query/45376/exchange-v2-zksync-testnet/version/latest",
    [324 /* ZKSYNC */]: " https://api.studio.thegraph.com/query/45376/exchange-v2-zksync/version/latest",
    [59140 /* LINEA_TESTNET */]: "https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exhange-eth/",
    [42161 /* ARBITRUM_ONE */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v2-arb",
    [59144 /* LINEA */]: "https://api.studio.thegraph.com/query/45376/exchange-v2-linea/version/latest",
    [34504 /* ZEUSCHAIN */]: "https://graph.zeusx.io/subgraphs/name/v2-node",
    [369 /* PULSECHAIN */]: "https://graph.astraswap.io/subgraphs/name/v2-pulse",
    [1301 /* UNICHAIN_SEPOLIA */]: "https://api.studio.thegraph.com/query/91444/unichain/version/latest",
    [480 /* WORLDCHAIN */]: "https://graph-worldchain.astraswap.io/subgraphs/name/v2-node",
    [33139 /* APECHAIN */]: "https://graph-apechain.astraswap.io/subgraphs/name/v2-node",
    [2818 /* MORPHCHAIN */]: "https://graph-morphchain.astraswap.io/subgraphs/name/v2-node",
    [763373 /* INKCHAIN */]: "https://graph-inkchain.astraswap.io/subgraphs/name/v2-node",
    [8453 /* BASECHAIN */]: "https://api.studio.thegraph.com/query/91444/base-v2/version/latest",
    [204 /* OPBNB */]: `https://open-platform-ap.nodereal.io/${noderealApiKey}/opbnb-mainnet-graph-query/subgraphs/name/pancakeswap/exchange-v2`
  };
}
function getBlocksSubgraphs({ noderealApiKey }) {
  return {
    [56 /* BSC */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/blocks",
    [1 /* ETHEREUM */]: "https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks",
    [1101 /* POLYGON_ZKEVM */]: "https://api.studio.thegraph.com/query/45376/polygon-zkevm-block/version/latest",
    [324 /* ZKSYNC */]: "https://api.studio.thegraph.com/query/45376/blocks-zksync/version/latest",
    [42161 /* ARBITRUM_ONE */]: "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks",
    [59144 /* LINEA */]: "https://api.studio.thegraph.com/query/45376/blocks-linea/version/latest",
    [34504 /* ZEUSCHAIN */]: "https://graph.pulsechain.com/subgraphs/name/pulsechain/blocks",
    [369 /* PULSECHAIN */]: "https://graph.pulsechain.com/subgraphs/name/pulsechain/blocks",
    [1301 /* UNICHAIN_SEPOLIA */]: "https://api.studio.thegraph.com/query/91444/unichain-blocks/version/latest",
    [480 /* WORLDCHAIN */]: "https://graph-worldchain.astraswap.io/subgraphs/name/block-client",
    [33139 /* APECHAIN */]: "https://graph-apechain.astraswap.io/subgraphs/name/block-client",
    [2818 /* MORPHCHAIN */]: "https://graph-morphchain.astraswap.io/subgraphs/name/block-client",
    [763373 /* INKCHAIN */]: "https://graph-inkchain.astraswap.io/subgraphs/name/block-client",
    [8453 /* BASECHAIN */]: "https://api.studio.thegraph.com/query/48211/base-blocks/version/latest",
    [204 /* OPBNB */]: `https://open-platform-ap.nodereal.io/${noderealApiKey}/opbnb-mainnet-graph-query/subgraphs/name/pancakeswap/blocks`
  };
}

export { BLOCKS_SUBGRAPHS, ChainId, STABLESWAP_SUBGRAPHS, V2_SUBGRAPHS, V3_SUBGRAPHS, apeChain, baseChain, chainNameToChainId, chainNames, defaultChainId, defaultChainName, defiLlamaChainNames, getBlocksSubgraphs, getChainIdByChainName, getChainName, getLlamaChainName, getV2Subgraphs, getV3Subgraphs, inkChain, isTestnetChainId, morphChain, pulseChain, testnetChainIds, unichainSepolia, worldChain, zeusChain };
